































































import mixins from "vue-typed-mixins";
import { mapState } from "vuex";
import CheckboxReportModal from "@/components/Reports/CheckboxReportModal.vue";
import BaseEditorTemplate from "./BaseEditorTemplate.vue";
import { uuid } from "vue-uuid";
import userMixin from "@/mixins/user";
import {
  analyticsLogEvent,
  analyticsLogEventGenerator
} from "@/helpers/analyticsHelpers";
import * as gaEventNames from "@/options/analyticsOptions";
import { CheckboxBlockConfig, ItemConfig } from "@/options/editorOptions";
import { debounce } from "lodash";
import moment from "moment";
import { mdiPlus } from "@mdi/js";

export default mixins(userMixin).extend({
  name: "CheckboxEditor",
  components: { BaseEditorTemplate, CheckboxReportModal },
  props: {
    block: {
      type: Object as () => CheckboxBlockConfig,
      default: () => ({} as CheckboxBlockConfig)
    },
    editable: {
      type: Boolean,
      default: true
    },
    titleStyles: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      mouseOver: false,
      focusItemId: null,
      editorFocused: false,
      dialog: false,
      skipInitialLoad: true,
      svgPlus: mdiPlus
    };
  },
  computed: {
    ...mapState("boards", ["currentBoard"]),
    progress(): number {
      if (!this.block.items || this.block.items.length === 0) return 0;
      const doneItems = this.block.items.filter(obj => obj.done);
      return (doneItems.length / this.block.items.length) * 100;
    }
  },
  watch: {
    progress(val: number) {
      if (val === 100) {
        analyticsLogEventGenerator(
          this.block.assignment,
          this.currentBoard,
          "done"
        );
      }
    },
    "block.content"(val: string) {
      const dom = new DOMParser().parseFromString(val, "text/html");
      const myNodelist = dom.querySelectorAll("li[data-type='todo_item']");

      const newItems: ItemConfig[] = [];
      myNodelist.forEach(item => {
        newItems.push({
          itemId: item.getAttribute("data-item-id") || "",
          content: (item as HTMLElement).innerText,
          done: item.getAttribute("data-done") === "true"
        });
      });

      if (!this.skipInitialLoad) {
        this.logAnalytics();
      } else {
        this.skipInitialLoad = false;
      }

      this.block.items = newItems;
    }
  },
  async mounted() {
    await this.isOwner(this.currentBoard.id);
    if (this.block.history.length === 0) {
      this._saveDay();
    }
  },
  methods: {
    onMouseOver() {
      this.mouseOver = true;
    },
    onMouseLeave() {
      this.mouseOver = false;
    },
    logAnalytics: debounce(function(this: any) {
      // Debounced for 2 seconds so that we don't "spam" on every keystroke
      if (this.currentBoard) {
        analyticsLogEvent(gaEventNames.board_block_updated, {
          board_id: this.currentBoard.id,
          board_user_id: this.currentBoard.user,
          board_editor_type: this.block.assignment
        });

        analyticsLogEventGenerator(
          this.block.assignment,
          this.currentBoard,
          "updated"
        );
      }
    }, 2000),
    async newDay() {
      if (await this.$confirm("Are you sure?")) {
        this._saveDay();

        analyticsLogEvent(gaEventNames.board_day_saved, {
          board_id: this.currentBoard.id,
          board_user_id: this.currentBoard.user,
          board_editor_type: this.block.assignment
        });

        analyticsLogEventGenerator(
          this.block.assignment,
          this.currentBoard,
          "new_day_started"
        );
      }
    },
    _saveDay() {
      const currentDate = moment();
      if (this.block.history.length > 0) {
        if (
          moment
            .unix(this.block.history[this.block.history.length - 1].createdAt)
            .isSame(currentDate, "day")
        ) {
          this.block.history.pop();
        } else {
          this.block.history[
            this.block.history.length - 1
          ].content = this.block.content;
          const itemsCopy = JSON.parse(JSON.stringify(this.block.items));
          this.block.history[this.block.history.length - 1].items = itemsCopy;
        }

        if (this.block.removeDone) {
          this.block.items = JSON.parse(
            JSON.stringify(this.block.items.filter(x => !x.done))
          );
          this._addItemsToEditorContent();
        }
      }

      this.block.items.forEach(x => {
        x.done = false;
      });
      this._addItemsToEditorContent();
      const copy = JSON.parse(JSON.stringify(this.block.items));
      this.block.history.push({
        createdAt: currentDate.unix(),
        content: this.block.content,
        items: copy
      });
    },
    _addItemsToEditorContent() {
      const itemContent =
        this.block.items.length > 0
          ? this.block.items.map(x => {
              return {
                type: "todo_item",
                attrs: {
                  done: false,
                  itemId: x.itemId
                },
                content: [
                  {
                    type: "paragraph",
                    attrs: {
                      align: "left"
                    },
                    content: [
                      {
                        type: "text",
                        text: x.content
                      }
                    ]
                  }
                ]
              };
            })
          : [
              {
                type: "todo_item",
                attrs: {
                  done: false,
                  itemId: uuid.v4()
                },
                content: [
                  {
                    type: "paragraph",
                    attrs: {
                      align: "left"
                    },
                    content: [
                      {
                        type: "text",
                        text: "..."
                      }
                    ]
                  }
                ]
              }
            ];
      const json = {
        type: "todo_list",
        attrs: {},
        content: itemContent
      };

      ((this.$refs.baseEditorTemplate as Vue).$refs.baseEditor as Vue & {
        addJSONContent: (json: any) => void;
      }).addJSONContent(json);
    }
  }
});
