










import Vue from "vue";
import BaseEditorTemplate from "./BaseEditorTemplate.vue";
import { debounce } from "lodash";
import {
  analyticsLogEvent,
  analyticsLogEventGenerator
} from "@/helpers/analyticsHelpers";
import * as gaEventNames from "@/options/analyticsOptions";
import { mapState } from "vuex";
import { TextBlockConfig } from "@/options/editorOptions";

export default Vue.extend({
  name: "TextEditor",
  components: { BaseEditorTemplate },
  props: {
    block: {
      type: Object as () => TextBlockConfig,
      default: () => ({} as TextBlockConfig)
    },
    editable: {
      type: Boolean,
      default: true
    },
    titleStyles: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapState("boards", ["currentBoard"])
  },
  watch: {
    "block.content": function() {
      this.logAnalytics();
    }
  },
  methods: {
    logAnalytics: debounce(function(this: any) {
      // Debounced for 2 seconds so that we don't "spam" on every keystroke
      if (this.currentBoard) {
        analyticsLogEvent(gaEventNames.board_block_updated, {
          board_id: this.currentBoard.id,
          board_user_id: this.currentBoard.user,
          board_editor_type: this.block.assignment
        });

        analyticsLogEventGenerator(
          this.block.assignment,
          this.currentBoard,
          "updated"
        );
      }
    }, 2000)
  }
});
