import Vue from "vue";
import TextEditor from "@/components/Editors/TextEditor.vue";
import CheckboxEditor from "@/components/Editors/CheckboxEditor.vue";
import JournalEditor from "@/components/Editors/JournalEditor.vue";
import QuoteEditor from "@/components/Editors/QuoteEditor.vue";
import { isCheckboxEditor } from "@/helpers/editorsHelpers";

export default Vue.component("EditorWrapper", {
  functional: true,
  props: {
    block: {
      type: Object,
      default: () => ({})
    },
    editable: {
      type: Boolean,
      default: true
    },
    titleStyles: {
      type: Object,
      default: () => ({})
    }
  },
  render(createElement, { props, data, children }) {
    function appropriateEditor() {
      switch (props.block.type) {
        case "text":
          if (isCheckboxEditor(props.block.textType)) {
            return CheckboxEditor;
          } else {
            return TextEditor;
          }
        case "quotes":
          return QuoteEditor;
        case "journal":
          return JournalEditor;
      }
    }

    return createElement(
      appropriateEditor(),
      {
        props,
        ...data
      },
      children
    );
  }
});
