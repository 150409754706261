




































































import Vue from "vue";
import moment from "moment";
import { HistoryConfig, ItemConfig } from "@/options/editorOptions";
import { mdiRadioboxBlank, mdiRadioboxMarked, mdiCalendar } from "@mdi/js";

const PERIOD = {
  WEEK: 1,
  MONTH: 2,
  RANGE: 3
};
type ReportConfig = { data: number; label: string; items: ItemConfig[] };

export default Vue.extend({
  name: "CheckboxReportModal",
  components: {
    Chart: () =>
      import(
        /* webpackPrefetch: true */ "@/components/Reports/Charts/CheckboxChart.vue"
      )
  },
  props: {
    history: {
      type: Array as () => HistoryConfig[],
      default: () => [] as HistoryConfig[]
    }
  },
  data() {
    return {
      today: moment()
        .subtract(1, "day")
        .format("YYYY-MM-DD"),
      lastWeekStart: moment().subtract(7, "days"),
      lastWeekEnd: moment(),
      lastMonthStart: moment().subtract(1, "month"),
      lastMonthEnd: moment(),
      period: PERIOD,
      radio: PERIOD.WEEK,
      menu: false,
      dates: [] as moment.Moment[],
      svgCalendar: mdiCalendar,
      svgRadioboxBlank: mdiRadioboxBlank,
      svgRadioboxMarked: mdiRadioboxMarked
    };
  },
  computed: {
    dateRangeText(): string {
      return this.dates.join(" ~ ");
    },
    dataSet(): ReportConfig[] {
      let result: ReportConfig[] = [];
      switch (this.radio) {
        case PERIOD.WEEK:
          for (
            let m = moment(this.lastWeekStart);
            m.isBefore(this.lastWeekEnd);
            m.add(1, "days")
          ) {
            const h = this.history.find(x =>
              moment.unix(x.createdAt).isSame(m, "day")
            );
            result.push({
              data: h
                ? (h.items.filter(obj => obj.done).length / h.items.length) *
                  100
                : 0,
              label: m.format("dddd"),
              items: h?.items || []
            });
          }
          break;
        case PERIOD.MONTH:
          for (
            let m = moment(this.lastMonthStart);
            m.isBefore(this.lastMonthEnd);
            m.add(1, "days")
          ) {
            const h = this.history.find(x =>
              moment.unix(x.createdAt).isSame(m, "day")
            );
            result.push({
              data: h
                ? (h.items.filter(obj => obj.done).length / h.items.length) *
                  100
                : 0,
              label: m.format("YYYY-MM-DD"),
              items: h?.items || []
            });
          }
          break;
        case PERIOD.RANGE:
          if (this.dates.length === 2) {
            const start =
              moment(this.dates[0]) <= moment(this.dates[1])
                ? moment(this.dates[0])
                : moment(this.dates[1]);
            const end =
              moment(this.dates[1]) > moment(this.dates[0])
                ? moment(this.dates[1])
                : moment(this.dates[0]);

            result = this.history
              .filter(
                f =>
                  moment.unix(f.createdAt).isSameOrAfter(start, "day") &&
                  moment.unix(f.createdAt).isSameOrBefore(end, "day")
              )
              .map(h => {
                return {
                  data:
                    (h.items.filter(obj => obj.done).length / h.items.length) *
                    100,
                  label: moment.unix(h.createdAt).format("YYYY-MM-DD"),
                  items: h.items
                };
              });
          }
      }
      return result;
    }
  }
});
