
















































































































import mixins from "vue-typed-mixins";
import { mapActions, mapState } from "vuex";
import { debounce, orderBy } from "lodash";
import BaseEditor from "./BaseEditor.vue";
import userMixin from "@/mixins/user";
import { uuid } from "vue-uuid";
import { analyticsLogEvent } from "@/helpers/analyticsHelpers";
import * as gaEventNames from "@/options/analyticsOptions";
import { mdiChevronRight, mdiChevronLeft, mdiPlus } from "@mdi/js";
import { JournalBlockConfig, PostConfig } from "@/options/editorOptions";
import moment from "moment";
import { toggleIntercomButton } from "@/plugins/intercom";

export default mixins(userMixin).extend({
  name: "JournalEditor",
  components: { BaseEditor },
  props: {
    block: {
      type: Object as () => JournalBlockConfig,
      default: () => ({} as JournalBlockConfig)
    },
    titleStyles: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      moment: moment,
      page: 1,
      perPage: 1,
      mouseOver: false,
      componentKey: 0,
      templateModal: false,
      showPrevPosts: false,
      dialog: false,
      svgChevronLeft: mdiChevronLeft,
      svgChevronRight: mdiChevronRight,
      svgPlus: mdiPlus
    };
  },
  computed: {
    ...mapState("boards", ["currentBoard"]),
    prevPosts(): PostConfig[] {
      return this.block.posts || [];
    },
    visiblePosts(): PostConfig[] {
      const prevPosts = orderBy(this.prevPosts, "createdAt", "desc");

      return prevPosts.slice(
        (this.page - 1) * this.perPage,
        this.page * this.perPage
      );
    },
    content: {
      get(): string {
        if (this.block.content) {
          return this.block.content;
        } else {
          return this.block.template;
        }
      },
      set(val: string) {
        this.block.content = val;
      }
    },
    todayPost(): PostConfig | undefined {
      return this.block.posts.find(item =>
        moment.unix(item.createdAt).isSame(moment(), "day")
      );
    },
    prevDayPost(): PostConfig | undefined {
      const currentDate = moment();
      const yesterday = currentDate.subtract(1, "days");
      return this.block.posts.find(item =>
        moment.unix(item.createdAt).isSame(yesterday, "day")
      );
    }
  },
  watch: {
    content: function() {
      this.logAnalytics();
    },
    templateModal: function(newVal) {
      toggleIntercomButton(newVal);
    }
  },
  async mounted() {
    await this.isOwner(this.currentBoard.id);
  },
  methods: {
    ...mapActions("boards", ["saveBoard"]),
    logAnalytics: debounce(function(this: any) {
      // Debounced for 2 seconds so that we don't "spam" on every keystroke
      if (this.currentBoard)
        analyticsLogEvent(gaEventNames.board_block_updated, {
          board_id: this.currentBoard.id,
          board_user_id: this.currentBoard.user,
          board_editor_type: this.block.assignment
        });
    }, 2000),
    async saveDay() {
      if (await this.$confirm("Are you sure?")) {
        const currentDate = moment();
        this.block.posts.push({
          postId: uuid.v4(),
          createdAt: currentDate.unix(),
          content: this.content
        });
        this.block.content = "";
      }
    },
    onMouseOver() {
      this.mouseOver = true;
    },
    onMouseLeave() {
      this.mouseOver = false;
    },
    async deletePost(post: PostConfig) {
      if (await this.$confirm("Are you sure?")) {
        this.block.posts = this.block.posts.filter(
          item =>
            !moment
              .unix(item.createdAt)
              .isSame(moment.unix(post.createdAt), "day")
        );
        this.saveBoard();
      }
    },
    updateJournalTemplate() {
      this.saveBoard();
      if (!this.todayPost) {
        this.componentKey += 1;
      }
      this.templateModal = false;
    }
  }
});
