





























import Vue from "vue";
import BaseEditor from "./BaseEditor.vue";
import {
  TextBlockConfig,
  CheckboxBlockConfig,
  JournalBlockConfig,
  QuotesBlockConfig
} from "@/options/editorOptions";

export default Vue.extend({
  name: "BaseEditorTemplate",
  components: { BaseEditor },
  props: {
    block: {
      type: Object as () =>
        | TextBlockConfig
        | CheckboxBlockConfig
        | JournalBlockConfig
        | QuotesBlockConfig,
      default: () => ({})
    },
    tooltip: {
      type: String,
      default: ""
    },
    editable: {
      type: Boolean,
      default: true
    },
    titleStyles: {
      type: Object,
      default: () => ({})
    }
  }
});
