



























import Vue from "vue";
import BaseEditor from "./BaseEditor.vue";
import { debounce } from "lodash";
import { analyticsLogEvent } from "@/helpers/analyticsHelpers";
import * as gaEventNames from "@/options/analyticsOptions";
import { mapState } from "vuex";
import { QuotesBlockConfig, QuoteConfig } from "@/options/editorOptions";
import moment from "moment";

export default Vue.extend({
  name: "QuoteEditor",
  components: { BaseEditor },
  props: {
    block: {
      type: Object as () => QuotesBlockConfig,
      default: () => ({} as QuotesBlockConfig)
    },
    titleStyles: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      show: false,
      slideIndex: 0,
      currentSlide: null as null | QuoteConfig
    };
  },
  computed: {
    ...mapState("boards", ["currentBoard"]),
    quotesContent(): string[] {
      return this.currentQuotes.map(quote => quote.content);
    },
    currentQuotes(): QuoteConfig[] {
      const currentDate = moment().startOf("day");

      return this.block.quotes
        .slice()
        .filter(
          x =>
            (!x.date && !x.range) ||
            (x.range?.length &&
              currentDate.unix() >= x.range[0] &&
              currentDate.unix() <= x.range[1]) ||
            (x.date && x.date.value === currentDate.unix()) ||
            (x.date &&
              x.date.value <= currentDate.unix() &&
              moment.unix(x.date.value).day() === currentDate.day())
        )
        .sort((a, b) => b.createdAt - a.createdAt);
    }
  },
  watch: {
    quotesContent(newVal, oldVal) {
      newVal.forEach((s: string, i: number) => {
        if (s !== oldVal[i]) {
          this.logAnalytics();
        }
      });
    },
    "currentQuotes.length": {
      immediate: true,
      handler(val) {
        if (val > 0) this.showSlides(this.slideIndex);
      }
    }
  },
  methods: {
    logAnalytics: debounce(function(this: any) {
      // Debounced for 2 seconds so that we don't "spam" on every keystroke
      if (this.currentBoard)
        analyticsLogEvent(gaEventNames.board_block_updated, {
          board_id: this.currentBoard.id,
          board_user_id: this.currentBoard.user,
          board_editor_type: this.block.assignment
        });
    }, 2000),
    showSlides(n: number) {
      const slidesLength = this.currentQuotes.length;
      if (n >= slidesLength) {
        this.slideIndex = 1;
      }
      if (n < 0) {
        this.slideIndex = slidesLength - 1;
      }
      this.currentSlide = this.currentQuotes[this.slideIndex];
    },
    plusSlides(n: number) {
      this.slideIndex += n;
      this.showSlides(this.slideIndex);
    }
  }
});
